<!--eslint-disable vue/camelcase-->
<template>
  <div class="landingPage">
    <div v-if="user" class="row">
      <div class="col-12">
        <div class="card rounded-lg">
          <div class="card-body">
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="letterForm"
            >
              <form
                  class="letter-form d-flex flex-column"
                  @submit.prevent="handleSubmit(submitFile)"
              >
                <div class="row">
                  <div class="col-lg-3">
                    <div class="row">
                      <div class="col"><TextInput
                          v-model="searchForm.keyword"
                          name="keyword"
                          rules="required"
                          label="Description"
                      /></div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <label>Choose a Template</label>
                        <SelectInput v-model="searchForm.letter_id" name="Template Page" rules="required" :options="letters" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="row">
                      <div class="col">
                        <label>Choose a Landing Page</label>
                        <SelectInput v-model="searchForm.page_id" name="Landing Page" rules="required" :options="pages" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <div class="col">
                            <label class="d-block">File Upload <small>(<a :href="csvDownloadLink" class="">Download Sample</a>)</small></label>
                            <input ref="fileInput" type="file" name="bulk" class="d-block" @change="setFile"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="row">
                      <div class="col">
                        <label class="d-block"><span style="visibility: hidden">Upload</span></label>
                        <b-button type="submit" :disabled="uploading || (invalid && touched)" variant="success" class="px-5 d-block">
                          <b-spinner v-if="uploading" small />
                          <span v-else>Upload</span>
                        </b-button>
                      </div>
                    </div>
                  </div>
            </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div v-if="total_uploaded > 0" class="card rounded-lg">
          <div class="card-body">
            <div class="font-weight-bold mb-3">Recent Uploads</div>
            <div class="tablerounededCorner">
              <table class="rounded-3 table table-striped roundedTable">
                <thead>
                <tr>
                  <th>Date</th>
                  <th>Keyword</th>
                  <th>City</th>
                  <th>Letter Template</th>
                  <th>Landing Page</th>
                  <th>Leads</th>
                  <th style="width: 200px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="search in total_uploaded_searches" :key="search.id">
                  <td>{{search.front_date}}</td>
                  <td>{{search.keyword}}</td>
                  <td>{{search.city}}</td>
                  <td>
                    {{search.letter.name}}
                  </td>
                  <td>
                    {{search.page.name}}
                  </td>
                  <td>
                    <b-spinner v-if="!search.complete && !search.stats.leads_sum" small />
                    <div v-else>
                      {{search.stats.leads_sum}}
                    </div>
                  </td>
                  <td style="width: 210px;" class="text-left">
                    <b-spinner v-if="!search.complete && !search.stats.leads_sum" small />
                    <div v-else>
                      <router-link :to="{ name: 'agency.campaign-leads', params: { id: search.id } }" class="btn btn-sm btn-success">View Leads</router-link>
                      <b-button v-if="search.mailed || search.isPrinting" size="sm" variant="light" class="ml-2 text-white" style="background: #a6a6a6;" @click="printLetter(search)">Reprint Letters</b-button>
                      <b-button v-else size="sm" variant="primary" class="ml-2" @click="printLetter(search)">Print Letters</b-button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <b-modal v-model="selectedPrint.isPrinting" :title="selectedPrint.keyword + ', ' + selectedPrint.city + ' Letters'" :hide-footer="true" size="lg" @close="closePrint">
            <div class="text-center">
              <b-spinner v-if="pdfLoading" />
            </div>
            <embed ref="pdfEmbed" :src="pdfURL(selectedPrint.id)" width="100%" style="min-height: 75vh" @load="pdfLoading = false" />
            <b-button v-if="!pdfLoading" class="w-100 d-block mt-2" @click="downloadPDF">Download Letters</b-button>
          </b-modal>
          <b-modal ref="uploadErrorModal" :hide-footer="true" title="CSV Upload Error">
              <ul class="font-weight-bold text-danger">
                <li v-for="(error,i) in error_messages" :key="'import-error' + i">{{error}}</li>
              </ul>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
export default {
  components:{
  },
  data() {
    return {
      user: {
        agency: {
          whitelabel: {},
          landing_page: {}
        },
      },
      uploading:false,
      pdfLoading:false,
      selectedPrint: {
        isPrinting:false,
        keyword:'',
        city:'',
        id:null
      },
      printLetters:false,
      searchForm:{
        keyword:null,
        city:"Manual",
        agency_id:null,
        letter_id:null,
        page_id:null,
        search_id:null
      },
      searches:[],
      letters:[],
      pages:[],
      fileUpload:null,
      loadingUpdate:false,
      total_uploaded:0,
      total_uploaded_searches:[],
      total_uploaded_page:1,
      error_messages:[]
    }
  },
  computed: {
    csvDownloadLink(){
      return  (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + 'lead-machine-import-sample';
    }
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.searchForm.agency_id = this.user.agency.id;
    this.getSearchData();
    this.getSearches();
    const pusherHost = (process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL);
    window.Pusher = Pusher;
    // window.Pusher.logToConsole = true;
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: '295948766410685f3c58',
      cluster: 'us3',
      forceTLS: true,
      authEndpoint: pusherHost+'api/broadcasting/auth',
      auth: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
      },
    });
    window.Echo.private("user." + this.user.id)
        .listen(".LeadMachineUploadComplete", (e) => {
          this.getSearches();
        })
        .listen(".LeadMachineUploadFailed", (e) => {
          this.error_messages = e.messages;
          this.uploading = false;
          this.$refs.uploadErrorModal.show();
        })
    ;


    /* var pusher = new Pusher('295948766410685f3c58', {
      cluster: 'us3'
    });

    var channel = pusher.subscribe('my-channel');
    channel.bind('my-event', function(data) {
      app.messages.push(JSON.stringify(data));
    }); */
  },
  methods: {
    setFile(){
      this.fileUpload = event.target.files[0];
    },
    closePrint(){
      const index = this.searches.findIndex(item => item.id === this.selectedPrint.id);
      if (index !== -1) {
        this.total -= 1;
        this.searches.splice(index, 1);
      }
      this.selectedPrint.isPrinting = false;
    },
    downloadPDF(){
      const pdfURL = this.$refs.pdfEmbed.getAttribute('src');

      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', pdfURL + '?download');
      link.click();
      document.body.removeChild(link);
    },
    printLetter(letter){
      this.pdfLoading = true;
      this.selectedPrint = letter;
      this.selectedPrint.isPrinting = true;
    },
    pdfURL(letterId){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + 'api/v1/agencies/print-letters/' + letterId + '/letters.pdf';
    },
    getSearchData(){
      this.$store
          .dispatch('agency/getImportData',{agency_id:this.user.agency.id})
          .then((resp) => {
            this.searches = resp.searches;
            this.letters = resp.letters;
            this.pages = resp.pages;
          })
          .catch((err) => {
            console.log(err);
          })
    },
    getSearches(){
      this.$store
          .dispatch('agency/getSearches',{agency_id:this.user.agency.id,page:1,only_uploaded:true})
          .then((resp) => {
            this.total_uploaded = resp.data.total;
            this.total_uploaded_searches = resp.data.data;
            this.uploading = false;
          })
          .catch((err) => {
            console.log(err);
            this.uploading = false;
          })
    },
    submitFile(){
      this.error_messages = [];
      this.loadingUpdate = true
      this.uploading = true;
      let uploadForm = new FormData();
      uploadForm.append('csv', this.fileUpload);
      uploadForm.append('form',JSON.stringify(this.searchForm));
      uploadForm.append('agency_id',this.user.agency.id);
      this.$store
          .dispatch('leads/uploadCsv', uploadForm)
          .then((resp) => {
            this.loadingUpdate = false
            this.fileUpload = null;
            this.$refs.fileInput.value = '';
          })
          .catch((err) => {
            console.log(err);
            this.loadingUpdate = false;
            this.uploading = false;
          })
    }
  },
}
</script>
<style lang="scss">
.tablerounededCorner {
  border: 1px solid #ccc;
  border-radius: 10px;
}
.roundedTable thead tr:last-child th{
  border-bottom: 1px solid #ccc;
}
.roundedTable {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.roundedTable th{
  padding: .6em;
}

.roundedTable td {
  padding: .4em;
}

.roundedTable th {
  text-align: left;
}

.roundedTable tr:last-child td {
  border-bottom: none;
}
.roundedTable.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eceff6;
}

</style>
